<template>
    <div class="content">
        <div class="main-box">
            <div class="main-box-left">
                <div class="title_area">
                    <span class="title_area_tip">本次赛事名称：</span>
                    <span class="title_area_title" v-if="!isEditTitle">{{matchTitle}}</span>
                    <el-input v-else v-model="matchTitle" placeholder="请输入赛事名称" @input="updateWidth" :style="{ width: inputWidth + 'px' }"></el-input>
                    <i v-if="!isEditTitle" class="el-icon-edit title-icon" @click="startEdit"></i>
                    <div class="tools-box" v-else style="display: inline-block">
                        <i class="el-icon-check title-icon" @click="editTitle"></i>
                        <i class="el-icon-close title-icon" @click="cancelEditTitle"></i>
                    </div>
                </div>
                <div class="match_box">
                    <div class="match-box-item">
                        <div class="select-mask">
                            <el-upload
                              class="upload-demo"
                              action=""
                              :auto-upload="false"
                              :show-file-list="false"
                              :on-change="event => handleChangeUpload(event,'first')">
                              <i class="el-icon-refresh change-icon">&nbsp;更换主图</i>
                            </el-upload>
                            
                        </div>
                        <!-- <img class="match-box-img" :src="firstImg" alt="" /> -->
						<el-image
						 class="match-box-img"
						 :src="firstImg"
						>
						    <div slot="error" class="image-slot">
						        加载中...
						    </div>
						</el-image>
                    </div>
                    <div class="match-box-item">
                        <div class="select-mask">
                            <el-upload
                              class="upload-demo"
                              action=""
                              :auto-upload="false"
                              :show-file-list="false"
                              :on-change="event => handleChangeUpload(event,'second')">
                              <i class="el-icon-refresh change-icon">&nbsp;更换副图</i>
                            </el-upload>
                        </div>
						<el-image
						 class="match-box-img"
						 :src="secondImg"
						>
						    <div slot="error" class="image-slot">
						        加载中...
						    </div>
						</el-image>
                        <!-- <img class="match-box-img" :src="secondImg" alt="" /> -->
                    </div>
                </div>
                
                <div class="state_box">
					<div style="width: 100%;display: flex;align-items: center;">
						<div class="match-box-info">
							<div class="match-box-info-item" @click="toTeam">
								<span class="match-box-info-item-num">{{playersNum || 0}}</span>
								<span class="match-box-info-item-name">注册球员</span>
							</div>
							<div class="line"></div>
							<div class="match-box-info-item">
								<span class="match-box-info-item-num">{{fansNum || 0}}</span>
								<span class="match-box-info-item-name">粉丝</span>
							</div>
							<div class="line"></div>
							<div class="match-box-info-item" @click="toRef">
								<span class="match-box-info-item-num">{{refsNum || 0}}</span>
								<span class="match-box-info-item-name">裁判</span>
							</div>
							<div class="line"></div>
							<div class="match-box-info-item" @click="toTeam">
								<span class="match-box-info-item-num">{{teamsNum || 0}}</span>
								<span class="match-box-info-item-name">参赛队伍</span>
							</div>
							
						</div>
						<span class="btn-area-link create-btn" style="margin-right: 50px;" @click="dialogLCVisible = true">生成报名流程图</span>
					</div>
                    <div class="state_box-item" style="border: 0;">
                        <div style="display: flex;">
                            <span class="state_box-item-title">队员报名状态: </span>
                            
                        </div>
                        <div class="btn-area">
                            <span class="state_box-item-state">{{ is_signup == 0 ? "正在报名中，点击结束报名将停止报名" : "报名已停止，点击重启报名将重新开启报名" }}</span>
                            <div style="width: 50%;text-align: right;">
                                
                                <span class="btn-area-link" @click="changeSignup(is_signup)">{{is_signup == 0 ? "结束报名" : "重启报名"}}</span>
                            </div>
                            
                        </div>
                    </div>
					<div class="state_box-item" style="border: 0;">
					    <div style="display: flex;">
					        <span class="state_box-item-title">裁判报名状态: </span>
					        
					    </div>
					    <div class="btn-area">
					        <span class="state_box-item-state">{{ isSignupReferee  == 0 ? "正在报名中，点击结束报名将停止报名" : "报名已停止，点击重启报名将重新开启报名" }}</span>
					        <div style="width: 50%;text-align: right;">
					            <!-- <span class="btn-area-link" style="margin-right: 50px;" @click="dialogLCVisible = true">生成报名流程图</span> -->
					            <span class="btn-area-link" @click="changeRefSignup(isSignupReferee )">{{isSignupReferee  == 0 ? "结束报名" : "重启报名"}}</span>
					        </div>
					        
					    </div>
					</div>
                    <div class="state_box-item">
                        <div style="display: flex;">
                            <div style="width: 50%;text-align: right;">
                                <span class="state_box-item-title">导出报名表: </span>
                            </div>
                        </div>
                        <div class="btn-area">
                            <!-- <button class="state_box-item-btn">导出报名表</button> -->
                            
                                <span class="state_box-item-state">可以导出本次比赛报名表</span>
                            <div style="width: 50%;text-align: right;">
                                <span class="btn-area-link" @click="exportSignExcel()">导出报名表</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="state_box-item">
                        <div style="display: flex;">
                            <span class="state_box-item-title">{{ is_Auth == 0 ? "开启身份验证" : "关闭身份验证" }}: </span>
                            
                        </div>
                        <div class="btn-area">
                            <span class="state_box-item-state">{{ is_Auth == 0 ? "开启身份验证后，报名将上传身份证和户口薄进行认证" : "关闭身份验证后，报名将无需实名认证" }}</span>
                            <div style="width: 50%;text-align: right;">
                                <span class="btn-area-link" @click="changeAuth(is_Auth)">{{ is_Auth == 0 ? "开启身份验证" : "关闭身份验证" }}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="state_box-item">
                        <div style="display: flex;">
                            <span class="state_box-item-title">创建球队状态: </span>
                            
                        </div>
                        <div class="btn-area">
                            <!-- <button class="state_box-item-btn">开启用户创建</button> -->
                            <span class="state_box-item-state">{{ is_create == 0 ? "管理员创建球队，如果开启用户创建，球员可以自己创建球队" : "用户创建球队，如果关闭用户创建，仅管理员可创建球队" }}</span>
                            <div style="width: 50%;text-align: right;">
                                <span class="btn-area-link" @click="changeCreate(is_create)">{{ is_create == 0 ? "开启用户创建" : "关闭用户创建" }}</span>
                            </div>
                        </div>
                    </div>
                    
                    <!-- <div class="state_box-item">
                        <div style="display: flex;">
                            <span class="state_box-item-title">当前积分状态: </span>
                            
                        </div>
                        <div class="btn-area">
                             <button class="state_box-item-btn">停止积分计算</button>
                            <span class="state_box-item-state">{{is_liveend ? "本次赛事已结束" : "本次赛事进行中"}}</span>
                            <div style="width: 50%;text-align: right;">
                                <span class="btn-area-link" @click="changeScore()">{{is_scoreend == 0 ? "停止积分计算" : "重启积分计算"}}</span>
                                <span class="btn-area-link" style="margin-left: 30px;">进入赛事排名</span>
                            </div>
                        </div>
                    </div> -->
                    
                    <div class="state_box-item" style="display: flex;justify-content: space-between;">
                        <div>
                            <div style="display: flex;">
                                <span class="state_box-item-title">报名是否收费: </span>
                                
                            </div>
                            <div class="btn-area">
                                <!-- <button class="state_box-item-btn">停止积分计算</button> -->
                                <!-- <span class="state_box-item-state" style=""></span> -->
                                <div style="width: 100%;text-align: right;display: flex;justify-content: space-between;">
                                    <div>
     <!--                                   <span class="btn-area-link" @click="changePay(1)" style="margin-right: 40px">免费</span>
                                        <span class="btn-area-link" @click="changePay(2)" style="margin-right: 40px">报一个队伍收一次费用</span>
                                        <span class="btn-area-link" @click="changePay(3)" style="margin-right: 40px">报几个队伍收几次费用</span> -->
                                        <el-select v-model="joinType" placeholder="请选择">
                                            <el-option
                                              v-for="item in options"
                                              :key="item.value"
                                              :label="item.label"
                                              :value="item.value"
                                               @click.native="changePay(item.value)"
                                              >
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <el-input @focus="oldprice = price" @blur="changePrice" v-only-digits v-if="isShow" v-model="price" placeholder="收费金额" style="width: 200px;"><template slot="append">￥</template></el-input>
                                </div>
                            </div>
                        </div>
                        <div style="margin-right: 0px;text-align: right;">
                            <div style="display: flex;justify-content: right;padding-right: 40px;">
                               <span class="state_box-item-title">队伍报名人数限制</span>
                               
                           </div>
                           <div class="btn-area">
                               <!-- <button class="state_box-item-btn">停止积分计算</button> -->
                               <!-- <span class="state_box-item-state" style=""></span> -->
                               <div style="width: 100%;text-align: center;padding-right: 50px;">
                                   <el-input  @focus="oldmembers = members" @blur="changeMembers" v-only-digits  v-model="members" placeholder="16" style="width: 130px;margin-right: 20px;"><template slot="append">人</template></el-input>
                               </div>
                           </div>
                       </div>
                    </div>
                    
                </div>
            </div>
            <div class="main-box-right">
                <div style="width: 100%;text-align: center;">手机预览</div>
                <div class="mobile">
                    <div class="mobile-head"></div>
                    <div class="mobile-btn"></div>
                    <div class="mobile-main-box">
                        <div class="mobile-top-nav">
                            <span style="width: 40px;">本届</span>
                            <i class="el-icon-arrow-down"></i>
                            <el-input
                                placeholder="搜索队伍或队员"
                                prefix-icon="el-icon-search"
                                style="width: 180px;margin-left: 20px;"
                                >
                            </el-input>
                            <span style="margin-left: 20px;">其他赛事</span>
                        </div>
                        <el-carousel indicator-position="none" :interval="4000" type="card" height="90px" @change="carouselChange">
                            <el-carousel-item v-for="item in imgs" :key="item.id" :id="item.id">
                               <el-image :src="item.url"></el-image>
                            </el-carousel-item>
                        </el-carousel> 
                        <div class="block-box" style="display: flex;margin-top: 10px;">
                            <div class="block-box-l" style="width: 40%;">
                                <div class="block-box-t" style="background-color: #e9f1fe;padding: 5px;border-radius: 10px;margin-bottom: 5px;height: 180px;">
                                    <span class="block-box-title">队员报名</span>
                                    <ul style="list-style: none;padding-left: 0;margin-top: 3px;text-align: left;">
                                        <li>
                                            <i class="el-icon-circle-check"></i>
                                            免冠正面照</li>
                                        <li>
                                            <i class="el-icon-circle-check"></i>
                                            身份证号正确</li>
                                        <li>
                                            <i class="el-icon-circle-check"></i>
                                            只报一个队伍</li>
                                        <li>
                                            <i class="el-icon-circle-check"></i>
                                            队服号准确</li>
                                    </ul>
                                    <span>立即报名</span>
                                    <i class="el-icon-caret-right"></i>
                                </div>
                                <div 
                                    class="block-box-b" 
                                    style="background-color: #e7ffff;
                                            padding: 5px;
                                            border-radius: 10px;
                                            margin-bottom: 5px;
                                            display: flex;
                                            flex-direction: column;
                                    ">
                                    <span class="block-box-title">图库视频</span>
                                    <span>图0张视频0个</span>
                                </div>
                            </div>
                            <div class="block-box-r" style="margin-left: 5px;width: 60%;">
                                <div 
                                class="block-box-m" 
                                style="background-color: #fcf0f0;
                                padding: 5px;
                                border-radius: 10px;
                                margin-bottom: 5px;
                                display: flex;
                                ">
                                    <div style="display: flex;
                                            flex-direction: column;width: 40%;text-align: center;height: 84px;">
                                        <span class="block-box-title">联赛排名</span>
                                        <span style="font-size: 13px;color: #000;">0/402</span>
                                        <span>得分排名</span>
                                    </div>
                                    <div style="text-align: right;width: 60%;"><span>MVP榜</span>
                                    <i class="el-icon-caret-right"></i></div>
                                    
                                </div>
                                <div class="block-box-m" style="background-color: #f3faf3;padding: 5px;border-radius: 10px;margin-bottom: 5px;height: 80.5px;">
                                    <span class="block-box-title">现有</span>
                                    <div style="display: flex;justify-content: space-between;text-align: center;">
                                        <div style="display: flex;flex-direction: column;">
                                            <span style="color: #000;font-weight: bold">{{teamsNum}}</span>
                                            <span>队伍</span>
                                        </div>
                                        <div style="display: flex;flex-direction: column;">
                                            <span style="color: #000;font-weight: bold">{{playersNum}}</span>
                                            <span>队员</span>
                                        </div>
                                        <div style="display: flex;flex-direction: column;">
                                            <span style="color: #000;font-weight: bold">{{refsNum}}</span>
                                            <span>裁判</span>
                                        </div>
                                        <div style="display: flex;flex-direction: column;">
                                            <span style="color: #000;font-weight: bold">{{fansNum}}</span>
                                            <span>关注</span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="block-box-b" style="background-color: #fcf0f0;padding: 5px;border-radius: 10px;margin-bottom: 5px;display: flex;
                                            flex-direction: column">
                                    <span class="block-box-title">赛程表</span>
                                    <span>赛程安排、预测、成绩</span>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div style="background-color: #f4f4f4;padding: 10px 0px;">
                            <div style="display: flex;justify-content: space-between;">
                            <span class="block-box-title">活动人气</span>
                            <span>排行榜</span>
                            </div>
                            <div style="display: flex;justify-content: space-around;">
                                <div style="width: 31%;height: 160px;background-color: #fff;border-radius: 10px;display: flex;flex-direction: column;text-align:center">
                                    <img style="width: 60px;height: 60px;background-color: #000;border-radius: 60px;margin: 5px auto"  alt="" />
                                    <span>用户名</span>
                                    <span style="font-size: 12px">描述词汇</span>
                                    <span>累计人气 <span>135</span></span>
                                </div>
                                <div style="width: 31%;height: 160px;background-color: #fff;border-radius: 10px;display: flex;flex-direction: column;text-align:center">
                                    <img style="width: 60px;height: 60px;background-color: #000;border-radius: 60px;margin: 5px auto"  alt="" />
                                    <span>用户名</span>
                                    <span style="font-size: 12px">描述词汇</span>
                                    <span>累计人气 <span>94</span></span>
                                </div>
                                <div style="width: 31%;height: 160px;background-color: #fff;border-radius: 10px;display: flex;flex-direction: column;text-align:center">
                                    <img style="width: 60px;height: 60px;background-color: #000;border-radius: 60px;margin: 5px auto"  alt="" />
                                    <span>用户名</span>
                                    <span style="font-size: 12px">描述词汇</span>
                                    <span>累计人气 <span>82</span></span>
                                </div>
                            </div>
                        </div>
						
                    </div>
					<div class="qrcode-btn">
						<div class="qrcode-btn-circle"></div>
						<div class="qrcode-btn-line"></div>
						<div class="qrcode-btn-box" @click="dialogQrVisible = true">生成主页二维码</div>
					</div>
                    <!-- douzhan.club/resources/organ/#/pages/index/lqorgan?id=4（oid） -->
                </div>
				
            </div>
            <el-dialog
              title="用户报名流程图"
              :visible.sync="dialogLCVisible"
              width="30%"
              >
              <div class="join_box" id="exportTemplate">
                  <span class="join_box_title">{{matchTitle}}</span>
                  <img class="join_match_img" src="@/assets/images/join_match.jpg" alt="">
                  <img class="match_img" :src="firstImg" alt="">
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogLCVisible = false">关 闭</el-button>
                <el-button type="primary" @click="exportToPng()">保 存</el-button>
              </span>
            </el-dialog>
			<el-dialog
			  title="主页二维码"
			  :visible.sync="dialogQrVisible"
			  width="30%"
			  >
			  <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;background-color: #fff;" id="QrPng">
				  <img :src="userlogo" alt="" class="qr-head">
				  <span class="qr-name">{{username}}</span>
				  <span class="qr-name">{{matchTitle}}</span>
				  <img :src="firstImg" alt="" class="qr-img">
				  
				  <!-- <img src="@/assets/images/gt_qrcode.png" alt="" style="margin-top: 50px;"  class="qr-code"/> -->
				  <QrCodeGenerator :url="url" />
				  <span style="color: #666;margin-bottom: 20px;">微信扫码查看主办方首页</span>
				  
			  </div>
			  <el-button type="primary" style="" @click="exportQrToPng">保存图片</el-button>
			</el-dialog>
            <my-cropper
             :showDialog="dialogVisible"
             :image="cropperImage" 
             :width="cropperWidth"
             :height="cropperHeight"
             @update-logo="handleLogoUpdate" 
             @cancelDialog="closeDialog"
            ></my-cropper>
        </div>
    </div>
</template>

<script>
    import { saveAs } from 'file-saver';
    import domtoimage from 'dom-to-image';
	import QrCodeGenerator from '@/components/qrcode.vue'
    export default {
		components: {
		    QrCodeGenerator
		},
        directives: {
            'only-digits': {
              bind(el) {
                el.addEventListener('input', (event) => {
                    const inputValue = event.target.value;
                    event.target.value = inputValue.replace(/[^0-9]/g, '');
                        if (inputValue !== event.target.value) {
                            event.target.dispatchEvent(new Event('input'));
                        }
                    });
                },
            },
        },
        data() {
            return {
                options: [{
                  value: '2',
                  label: '免费'
                }, {
                  value: '1',
                  label: '报一个队伍收一次费用'
                }, {
                  value: '0',
                  label: '报几个队伍收几次费用'
                }],
                value: '0',
                isEditTitle: false,
                matchTitle: "斗战篮球赛事测试",
                tempTitle: '',
                dialogVisible: false,
                dialogLCVisible: false,
                cropperImage: "",
                cropperWidth: 500,
                cropperHeight: 250,
                firstImg: '',
                secondImg: require('@/assets/images/slider.jpg'),
                chooseImgBox: "",
                carouseId: 0,
                liveid: "",
                fansNum: 0,
                refsNum: 0,
                playersNum: 0,
                teamsNum: 0,
                is_signup: 1,
                isSignupReferee: 1,
                is_Auth: 0,
                is_create: 0,
                is_scoreend: 0,
                is_liveend: false,
                params: "name,teamsname,playtype,playno,height,weight,sfzh,tel,portrait,pregfee,unregfee",
                price: "",
                payType: "0",
                oldprice: 0,
                members: "",
                oldmembers: '',
                inputWidth: 100,
				dialogQrVisible: false,
				username: '',
				userlogo: '',
				url: "",
				joinType: '0',
				isShow: false
            }
        },
        mounted() {
            this.setPY();
        },
        created() {
            this.$api.post('api/Main/GetGame').then(response => {
               if (response.data.code == 0) {
                   this.$message({
                     type: 'error',
                     message: response.data.data
                   });
                   this.$router.push({ path: '/login'});
                   return;
               }
               this.liveid = response.data.data.id
               this.matchTitle = response.data.data.name
               this.is_signup = response.data.data.isSignup
               this.isSignupReferee = response.data.data.isSignupReferee
               this.is_Auth = response.data.data.idcardAuth
               this.is_create = response.data.data.isFreeteam
               this.is_scoreend = response.data.data.scoreend
               this.is_liveend = response.data.data.isLiveTimeUp
               this.firstImg = response.data.data.logo
               this.members = response.data.data.playernum
               this.price = response.data.data.regfee
			   
               this.joinType = response.data.data.regfeetype.toString()
			   if(this.price == 0) {
				   this.joinType = '2'
			   }
			   if (this.price > 0 || this.joinType != '2') {
			   		this.isShow = true
			   }
			   this.url = "https://www.douzhan.club/resources/organ/#/pages/index/lqorgan?id="+response.data.data.oid
               // this.secondImg = response.data.data.img1
				const currentProtocol = window.location.protocol;
				if (currentProtocol === 'https:') {
					this.firstImg = this.firstImg.replace(/^http:/, 'https:');
				}
               this.GetFansList()
               this.GetRefsList()
               this.GetPlayersList()
               this.GetTeamsList()
               this.updateWidth()
               
           }).catch(error => {
               console.error("There was an error!", error);
           }) 
		   const userinfo = JSON.parse(localStorage.getItem('userinfo'));
		   this.username = userinfo.name
		   this.userlogo = userinfo.logourl
        },
        computed: {
            // 使用计算属性来定义imgs
            imgs() {
              return [
                {
                    id: 0,
                    url: this.firstImg,
                },
                {
                    id: 1,
                    url: this.secondImg,
                },
                {
                    id: 2,
                    url: require("@/assets/images/slider2.jpg")
                },
              ];
            }
        },
        methods: {
            toTeam() {
                this.$router.push({ path: '/teammgmt'});
            },
            toRef() {
                this.$router.push({ path: '/refmgmt'});
            },
            startEdit() {
                this.tempTitle = this.matchTitle;
                this.isEditTitle = true
            },
            changeSignup(t) {
				let url = ""
				let msg = ""
				if (t == 0) {
					msg = "关闭报名后，所有队员不能进行报名，是否关闭报名通道"
					url = "api/Main/StopSignup"
				} else {
					msg = "重启报名后，所有队员可以在线报名，是否打开报名通道"
					url = "api/Main/StartSignup"
				}
				this.$confirm(msg, '提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {
				
					
					this.$api.post(url).then(response => {
						if (response.data.code == 1) {
							this.is_signup = Math.abs(this.is_signup - 1)
							this.$message({
							  type: 'success',
							  message: response.data.data
							});
						} else {
							this.$message({
								type: 'error',
								message: response.data.data
						   });
						} 
					})
					.catch(error => {
					  console.error("There was an error!", error);
					});
				}).catch(() => {
				    this.isEditTitle = false
				    this.$message({
				        type: 'info',
				        message: '取消操作'
				    });          
				}); 
            },
			changeRefSignup(t) {
				let url = ""
				let msg = ""
				if (t == 0) {
					msg = "关闭报名后，所有裁判不能进行报名，是否关闭报名通道"
					url = "api/Main/StopSignupReferee"
				} else {
					msg = "重启报名后，所有裁判可以在线报名，是否打开报名通道"
					url = "api/Main/StartSignupReferee"
				}
				this.$confirm(msg, '提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {
					this.$api.post(url).then(response => {
						if (response.data.code == 1) {
							this.isSignupReferee = Math.abs(this.isSignupReferee - 1)
							this.$message({
							  type: 'success',
							  message: response.data.data
							});
						} else {
							this.$message({
								type: 'error',
								message: response.data.data
						   });
						} 
					})
					.catch(error => {
					  console.error("There was an error!", error);
					});
				}).catch(() => {
				    this.isEditTitle = false
				    this.$message({
				        type: 'info',
				        message: '取消操作'
				    });          
				}); 
            },
            changeAuth(t) {
                let url = ""
                if (t == 1) {
                    url = "api/Main/idCardUnAuth"
                } else {
                    url = "api/Main/idCardAuth"
                }
                this.$api.post(url).then(response => {
                    if (response.data.code == 1) {
                        this.is_Auth = Math.abs(this.is_Auth - 1)
                        this.$message({
                          type: 'success',
                          message: response.data.data
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                       });
                    } 
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            changeCreate(t) {
                let url = ""
                if (t == 1) {
                    url = "api/Main/StopCreate"
                } else {
                    url = "api/Main/StartCreate"
                }
                this.$api.post(url).then(response => {
                    if (response.data.code == 1) {
                        this.is_create = Math.abs(this.is_create - 1)
                        this.$message({
                          type: 'success',
                          message: response.data.data
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                       });
                    } 
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            changeScore() {
                this.$api.post('api/Main/SetScoreEnd').then(response => {
                    if (response.data.code == 1) {
                        this.is_scoreend = Math.abs(this.is_scoreend - 1)
                        this.$message({
                          type: 'success',
                          message: response.data.data
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: response.data.data
                       });
                    } 
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            exportSignExcel() {
                this.$api.get(`/api/Main/execlplayers/${this.liveid}/${this.params}`,{
                    responseType: 'blob',
                }).then(response => {
                    let temp = response.headers["content-disposition"].split(";")[1].split("=")[1];
                    let iconv = require("iconv-lite");
                    let fileName = iconv.decode(temp, "gbk");
                    
                    const url = window.URL.createObjectURL(new Blob([response.data],{
                        type: "application/vnd.ms-excel;charset=UTF-8",
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    
                    
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();

                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(link);
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            GetFansList() {
                this.$api.get(`api/Main/favoritelist`,{
                  params: {
                    PageIndex: 1,
                  }
                }).then(response => {
                    this.fansNum = response.data.data.total
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            GetRefsList() {
                this.$api.get(`app/match/referees`,{
                  params: {
                    PageIndex: 1,
                    liveid: this.liveid
                  }
                }).then(response => {
                    this.refsNum = response.data.data.total
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            GetPlayersList() {
                this.$api.get(`api/Team/Players`,{
                  params: {
                    PageIndex: 1,
                    liveid: this.liveid,
                    teamid: 0
                  }
                }).then(response => {
                    this.playersNum = response.data.total
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            GetTeamsList() {
                this.$api.get('api/Team/Teams', {
                  params: {
                    limit: -1,
                    PageIndex: 1,
                  }
                })
                .then(response => {
                    this.teamsNum = response.data.total
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            editTitle() {
                this.$confirm('确定要將赛事名称修改为“'+this.matchTitle+'”吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let formData = new FormData();
                    formData.append('name', this.matchTitle);
                    this.$api.post('api/Main/resetgamename',formData).then(response => {
                        if (response.data.code == 1) {
                            this.$message({
                              type: 'success',
                              message: '修改成功!'
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: response.data.data
                           });
                        } 
                    })
                    .catch(error => {
                      console.error("There was an error!", error);
                    });
                    
                    this.isEditTitle = false
                }).catch(() => {
                    this.isEditTitle = false
                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
            },
            cancelEditTitle() {
                this.matchTitle = this.tempTitle;
                this.isEditTitle = false
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });
            },
            handleChangeUpload(file,i) {
                console.log(i)
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG/PNG 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传头图片大小不能超过 2MB!');
                    return false
                }
                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(async () => {
                    this.chooseImgBox = i
                    // base64方式
                    // this.option.img = await fileByBase64(file.raw)
                    console.log("成功", URL.createObjectURL(file.raw))
                    this.cropperImage = URL.createObjectURL(file.raw)
                    this.loading = false
                    this.dialogVisible = true
                })
            },
            handleLogoUpdate(croppedImageUrl) {
                if (this.chooseImgBox == "first") {
                    this.firstImg = croppedImageUrl
                } else {
                    this.secondImg = croppedImageUrl
                }
            },
            closeDialog() {
                this.dialogVisible = false
            },
            carouselChange:function(now,old){
                console.log(now+"，"+old);
                this.carouseId = now;
                this.setPY();
            },
            setPY:function(){
                var _carouseId = this.carouseId;
                let element = ""
                if(_carouseId == 0){
                    element = document.getElementById('0');
                    element.style.left = '0%';
                    element.nextElementSibling.style.left = "25%";				
                    element.nextElementSibling.nextElementSibling.style.left = "-25%";
                }else if(_carouseId == 1){
                    element = document.getElementById('1');
                    element.style.left = '0%';
                    element.previousElementSibling.style.left = "-25%";
                    element.nextElementSibling.style.left = "25%";
                }else{
                    element = document.getElementById('2');
                    element.style.left = '-0%';
                    element.previousElementSibling.style.left = "-25%";		
                    element.previousElementSibling.previousElementSibling.style.left = "25%";
                }
            },
            changePay(t) {
				console.log(t)
                let msg = ""
                switch(t) {
                    case "2": 
                        msg = "确认将报名收费方式改为免费吗？";
                        break;
                    case "1":
                        msg = "确认将报名收费方式改为报一个队伍收一次费用吗？";
                        break;
                    case "0":
                        msg = "确认将报名收费方式改为报几个队伍收几次费用吗？";
                        break;
                }
                this.$confirm(msg, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
					if (t == "2") {
						console.log("点击免费了")
						this.price = 0
						this.isShow = false
					} else {
						this.isShow = true
					}
					
                    this.updateMatchReg()
                }).catch(() => {

                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
                
            },
            changePrice() {
                this.$confirm("确定将报名费用设置为"+this.price+"元吗？", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
					this.updateMatchReg()
                    // this.$message({
                    //     type: 'success',
                    //     message: '修改成功!'
                    // });
                }).catch(() => {
                    this.price = this.oldprice
                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
            },
            changeMembers() {
                this.$confirm("确定将报名人数限制为"+this.members+"人吗？", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
					this.updateMatchReg()
                    // this.$message({
                    //     type: 'success',
                    //     message: '修改成功!'
                    // });
                }).catch(() => {
                    this.members = this.oldmembers
                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
            },
            async exportToPng() {
                const node = document.getElementById('exportTemplate');
                try {
                    const blob = await domtoimage.toBlob(node);
                    this.dialogLCVisible = false
                    const filename = this.matchTitle+"报名流程图"
                    saveAs(blob, filename+'.png');
                    
                } catch (err) {
                    console.error('Oops, something went wrong!', err);
                }
            },
			async exportQrToPng() {
                const node = document.getElementById('QrPng');
                try {
                    const blob = await domtoimage.toBlob(node);
                    this.dialogLCVisible = false
                    const filename = this.matchTitle
                    saveAs(blob, filename+'.png');
                } catch (err) {
                    console.error('Oops, something went wrong!', err);
                }
            },
            updateWidth() {
                console.log(this.matchTitle)
                const fontSize = 14;
                const textWidth = this.matchTitle.length * fontSize;
                this.inputWidth = textWidth > 200 ? textWidth : 200; // 设置最小宽度为100px
            },
			updateMatchReg() {
				let formData = new FormData();
				formData.append('liveid', this.liveid);
				formData.append('pnum', this.members);
				formData.append('money', this.price);
				formData.append('regfeetype', this.joinType);
				this.$api.post('api/Main/gameset',formData).then(response => {
				    if (response.data.code == 1) {
				        this.$message({
				          type: 'success',
				          message: '修改成功!'
				        });
				    } else {
				        this.$message({
				            type: 'error',
				            message: response.data.data
				       });
				    } 
				})
				.catch(error => {
				  console.error("There was an error!", error);
				});
			}
        }
        
    }
</script>

<style scoped>
    
    /* General styles */
    body {
        font-family: 'Arial', sans-serif;
        background-color: #f0f0f0;
        color: #333;
        line-height: 1.6;
        margin: 0;
        padding: 0;
    }
 
    .view-wrapper {
        background-color: transparent!important
    }
    
    .content {
        margin: 20px auto;
        padding: 20px;
    }
    
    /* Title Area */
    .title_area {
        
        text-align: left;
        background-color: #fff;
        height: 60px;
        line-height: 60px;
        margin: 0px 20px;
        margin-bottom: 20px;
        border-radius: 5px;
        text-indent: 2em;
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
    }
    
    .title_area_tip,
    .title_area_title {
        font-size: 18px;
        display: inline; 
        /* font-weight: bold; */
        color: #666;
    }
    
    .title_area_title {
        color: #000;
        margin-top: 5px;
    }
    
    /* Match Box */
    
    .match-box-item {
        width: calc(100% / 2 - 40px);
        margin: 0px 20px;
        position: relative;
        border-radius: 5px;
        background-color: #fff;
		aspect-ratio: 2/1;
		z-index: 99;
		
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
        
        
    }
    
    .select-mask {
        position: absolute;
        background-color: rgba(0, 0, 0, .6);
        width: 100%;
        height: 0%;
        border-radius: 5px;
        transition: height 0.2s ease;
        cursor: pointer;
        text-align: center;
        line-height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
		z-index: 99;
    }
    
    .match-box-item:hover .select-mask {
      height: 100%; /* 当鼠标悬停在父div上时，子div的高度变为100px */
    }
    
    .match-box-img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border-radius: 3px solid #fff;
		
        
    }
    
    .match-box-info {
        display: flex;
        justify-content: start;
        width: 100%;
        margin: 0 auto;
        padding-top: 40px;
        padding-bottom: 20px;
		padding-left: 40px;
        
    }
    
    .match-box-info-item {
        text-align: center;
        width: 100px;
        cursor: pointer;
    }
    
    .line {
        width: 1px;
        border-left: 1px solid #ccc;
        height: 50px;
        margin: 0px 20px;
        margin-top: 5px;
    }
    
    .match-box-info-item-num {
        font-size: 34px;
        color: #000;
        font-weight: bold;
    }
    
    .match-box-info-item-name {
        display: block;
        font-size: 16px;
        color: #666;
    }
    
    /* State Box */
    .state_box {
        background: #fff;
        padding: 10px 0px;
        margin: 20px;
        border-radius: 5px;
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
        
    }
    
    .state_box-item {
        margin-bottom: 10px;
        text-align: left;
        /* background-color: #f2f2f2; */
        padding: 10px 0px;
        
        /* height: 40px; */
        line-height: 40px;
        padding-right: 10px;
        border-top: 1px solid #f0f0f0;
        
        
    }
    
    .state_box-item-title {
        font-size: 15px;
        display: block; /* Improves readability */
        margin-bottom: 5px;
        
        color: #666;
        margin-left: 50px;
        width: 140px;
        text-align: left;
    }
    
    .state_box-item-state {
        width: 50%;
        text-align: left;
        font-size: 16px;
        margin-bottom: 5px;
    }
    
    .btn-shadow {
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
    }
    
    .state_box-item-btn {
        background-color: #007bff;
        margin-right: 20px;
        border: none;
        color: white;
        padding: 10px 15px;
        cursor: pointer;
        transition: background-color 0.2s;
        border-radius: 5px; /* Adds rounded corners to buttons */
    }
    
    .state_box-item-btn:hover {
        background-color: #0056b3;
    }
    
    .btn-area {
        display: flex;
        justify-content: left;
        text-align: left;
        
        width: 90%;
        padding-left: 50px;
    }
    
    .title-icon {
        margin-left: 10px;
        font-size: 18px;
        cursor: pointer;
        font-weight: bold!important;
    }
    
    .el-icon-check {
        color: #00a300;
    }
    
    .el-icon-close {
        color: red;
    }
    
    .main-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .main-box-left {
        /* background-color: #666; */
        width: 70%;
    }
    
    .main-box-right {
        width: 30%;
    }
    
    .mobile {
        height: 730px;
        border: 20px solid #dddddd;
        width: 360px;
        border-radius: 70px;
        position: relative;
        margin: 0 auto;
        margin-top: 20px;
        background-color: #fff;
        /* overflow: hidden; */
    }
    
    
    
    .mobile-head {
        width: 180px;
        height: 30px;
        background-color: #dddddd;
        margin: 0 auto;
        border-radius: 0px 0px 40px 40px;
    }
    
    
    .mobile-btn {
        width: 10px;
        height: 120px;
        border-radius: 0px 10px 10px 0px;
        background-color: #dddddd;
        position: absolute;
        right: -28px;
        top: 150px;
    }
    
    .mobile-main-box {
        padding: 10px;
        height: 670px;
        overflow-y: scroll;
    }
    
    .mobile-main-box::-webkit-scrollbar {
        display: none; /* 隐藏滚动条 */
    }
    
    /* 针对Firefox */
    .mobile-main-box {
        scrollbar-width: none; /* Firefox */
    }

    
    .match_box {
        display: flex;
        justify-content: space-between;
    }
    
    .change-icon {
        color: #fff;
        font-size: 20px;
    }
    
    .mobile-top-nav {
        display: flex;
        height: 40px;
        line-height: 40px;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .block-box-title {
        font-size: 18px;
        font-weight: bold;
    }
    
    .btn-area-link {
        color: #007bff;
        cursor: pointer
    }
    
    .btn-area-link:hover {
        color: #009dff;
    }
    
    .join_box {
        width: 100%;
        background-color: #fff;
        padding-top: 50px;
        
    }
    
    .join_box_title {
        font-size: 30px;
        font-weight: bold;
        color: #444;
        font-family: '宋体';
    }
    
    .join_match_img {
        width: 100%;
        margin-top: 20px;
    }
    
    .match_img {
        width: 100%;
    }
	
	.qrcode-btn {
		width: 150px;
		height: 150px;
		position: absolute;
		bottom: -100px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	
	.qrcode-btn-circle {
		width: 20px;
		height: 20px;
		border-radius: 20px;
		border: 3px solid #0056b3;
		margin: 0 auto;
	}
	
	.qrcode-btn-line {
		width: 10px;
		height: 100px;
		border-left: 3px dashed #0056b3;
		margin-left: 73px;
	}
	
	.qrcode-btn-box {
		width: 100%;
		height: 35px;
		line-height: 35px;
		border-radius: 5px;
		border: 2px solid #0056b3;
		background-color: #0056b3;
		color: #fff;
		cursor: pointer;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
	}
	
	.qr-head {
		width: 70px;
		height: 70px;
		margin: 10px;
	}
	
	.qr-name {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 15px;
	}
	
	.qr-img {
		width: 100%;
	}
	
	.create-btn {
		width: 200px;
		height: 40px;
		border-radius: 10px;
		background-color: #0056b3;
		line-height: 40px;
		color: #fff;
	}
	
	.create-btn:hover {
		color: #d8d8d8;
	}
    
    /* Adding responsiveness */
    @media (max-width: 768px) {
        .content {
            width: 95%;
            margin: 10px auto;
        }
    
        .match_box {
            flex-direction: column;
            align-items: start;
        }
    
        .match-box-img {
            margin-bottom: 20px;
        }
    
        .match-box-info {
            flex-direction: column;
        }
		
    
        .match-box-info-item {
            margin-bottom: 10px;
        }
		
		
        
        
    }


</style>