import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

// 导入你的组件
import HomePage from '@/pages/index.vue';
import indexPage from '@/pages/index/indexPage.vue';
import indexHistory from '@/pages/history/indexHistory.vue';
import createTeam from '@/pages/match/createTeam.vue';
import teamMgmt from '@/pages/match/teamMgmt.vue';
import fastGroup from '@/pages/match/fastGroup.vue';
import teamHistory from '@/pages/history/teamMgmt.vue';
import teamMembers from '@/pages/match/teamMembers.vue';
import teamMembersHistory from '@/pages/history/teamMembers.vue';
import pointsPage from '@/pages/match/pointsPage.vue';
import pointsHistory from '@/pages/history/pointsPage.vue';
import refMgmt from '@/pages/match/refMgmt.vue';
import schedulePage from '@/pages/match/schedulePage.vue';
import publicNotice from '@/pages/notice/publicNotice.vue';
import memberAuth from '@/pages/match/memberAuth.vue';
import listNotice from '@/pages/notice/listNotice.vue';
import pointDetail from '@/pages/match/pointDetail.vue';
import pointsDetailHistory from '@/pages/history/pointDetail.vue';
import loginPage from '@/pages/login/loginPage.vue';
import xiaoE from '@/pages/xiaoe/xiaoE.vue';
import resetPwd from '@/pages/index/resetPwd.vue';
import searchPage from '@/pages/index/searchPage.vue';
import guessPage from '@/pages/guess/guessPage.vue';
import registerPage from '@/pages/match/registerPage.vue';
import createGame from '@/pages/guess/createGame.vue';
import authPage from '@/pages/auth/indexPage.vue';
import historyPage from '@/pages/history/index.vue';
import CameraManagement from '@/pages/live/CameraManagement.vue';
import CameraSetting from '@/pages/live/CameraSetting.vue';
import activeLive from '@/pages/live/activeLive.vue';
import waterMarkSetting from '@/pages/live/waterMarkSetting.vue';
import createVote from '@/pages/vote/createVote.vue';
import createCert from '@/pages/cert/createCert.vue';
import printCert from '@/pages/cert/printCert.vue';
import articleLive from '@/pages/live/articleLive.vue';
import articleMgmt from '@/pages/live/articleMgmt.vue';
import shareIndex from '@/pages/share/shareIndex.vue';
import basketballBoard from '@/pages/share/basketballBoard.vue';
import videoMgmt from '@/pages/file/videoMgmt.vue';
import photoMgmt from '@/pages/file/photoMgmt.vue';
import fileMgmt from '@/pages/file/fileMgmt.vue';
import designMatch from '@/pages/match/designMatch.vue';
import boardBBS from '@/pages/share/boardBBS.vue';
import downloadSoftware from '@/pages/share/downloadSoftware.vue';
import mLogin from '@/pages/web/loginPage.vue';
import mCreate from '@/pages/web/createTeam.vue';
import mTeam from '@/pages/web/teamPage.vue';
import mPub from '@/pages/web/noticePage.vue';
import menu from '@/pages/web/menuPage.vue';
import mplayer from '@/pages/web/playerPage.vue';
import mtool from '@/pages/web/toolPage.vue';
import mref from '@/pages/web/refPage.vue';
import mlist from '@/pages/web/noticeList.vue';

Vue.use(Router);

const router = new Router({
	mode: 'hash',
	routes: [{
			path: '/',
			name: 'Home',
			component: HomePage,
			children: [{
					path: '/',
					name: 'indexPage',
					component: indexPage,
				},
				{
					path: '/createteam',
					name: 'createteam',
					component: createTeam,
				},
				{
					path: '/teammgmt',
					name: 'teammgmt',
					component: teamMgmt,
					props: true
				},
				{
					path: '/fastgroup',
					name: 'fastgroup',
					component: fastGroup,
					props: true
				},
				{
					path: '/teammembers/:t/:d',
					name: 'teammembers',
					component: teamMembers,
				},
				{
					path: '/memberauth',
					name: 'memberauth',
					component: memberAuth,
				},
				{
					path: '/points',
					name: 'points',
					component: pointsPage,
					props: true
				},
				{
					path: '/refmgmt',
					name: 'refmgmt',
					component: refMgmt,
				},
				{
					path: '/schedule',
					name: 'schedule',
					component: schedulePage,
				},
				{
					path: '/designmatch',
					name: 'designmatch',
					component: designMatch,
				},
				{
					path: '/pubnotice',
					name: 'pubnotice',
					component: publicNotice,
				},
				{
					path: '/listnotice',
					name: 'listnotice',
					component: listNotice,
				},
				{
					path: '/pointdetail/:t',
					name: 'pointdetail',
					component: pointDetail,
				},
				{
					path: '/resetpwd',
					name: 'resetpwd',
					component: resetPwd,
				},
				{
					path: '/search/:t/:p',
					name: 'search',
					component: searchPage,
				},
				{
					path: '/guess',
					name: 'guess',
					component: guessPage,
				},
				{
					path: '/register',
					name: 'register',
					component: registerPage,
				},
				{
					path: '/newgame',
					name: 'newgame',
					component: createGame,
				},
				{
					path: '/camera',
					name: 'camera',
					component: CameraManagement,
				},
				{
					path: '/camerasetting/:t/:d',
					name: 'camerasetting',
					component: CameraSetting,
				},
				{
					path: '/activelive',
					name: 'activelive',
					component: activeLive,
				},
				{
					path: '/articlelive',
					name: 'articlelive',
					component: articleLive,
				},
				{
					path: '/articlemgmt',
					name: 'articlemgmt',
					component: articleMgmt,
				},
				{
					path: '/watermark',
					name: 'watermark',
					component: waterMarkSetting,
				},
				{
					path: '/createvote',
					name: 'createvote',
					component: createVote,
				},
				{
					path: '/createcert',
					name: 'createcert',
					component: createCert,
				},
				{
					path: '/printcert',
					name: 'printcert',
					component: printCert,
				},
				{
					path: '/video',
					name: 'video',
					component: videoMgmt,
				},
				{
					path: '/photo',
					name: 'photo',
					component: photoMgmt,
				},
				{
					path: '/file',
					name: 'file',
					component: fileMgmt,
				},

			]
		},
		{
			path: '/login',
			name: 'login',
			component: xiaoE,
		},
		{
			path: '/e',
			name: 'e',
			component: loginPage,
		},
		{
			path: '/auth',
			name: 'auth',
			component: authPage,
		},
		{
			path: '/share',
			name: 'share',
			component: shareIndex,
		},
		{
			path: '/board',
			name: 'board',
			component: basketballBoard,
		},
		{
			path: '/bbs',
			name: 'bbs',
			component: boardBBS,
		},
		{
			path: '/download',
			name: 'download',
			component: downloadSoftware,
		},
		{
			path: '/mlogin',
			name: 'mlogin',
			component: mLogin,
		},
		{
			path: '/mcreate',
			name: 'mcreate',
			component: mCreate,
		},
		{
			path: '/mteam',
			name: 'mteam',
			component: mTeam,
		},
		{
			path: '/mpub',
			name: 'mpub',
			component: mPub,
		},
		{
			path: '/mplayer/:t/:d',
			name: 'mplayer',
			component: mplayer,
		},
		{
			path: '/menu',
			name: 'menu',
			component: menu,
		},
		{
			path: '/mtool',
			name: 'mtool',
			component: mtool,
		},
		{
			path: '/mref',
			name: 'mref',
			component: mref,
		},
		{
			path: '/mlist',
			name: 'mlist',
			component: mlist,
		},
		{
			path: '/history/:d',
			name: 'history',
			component: historyPage,
			children: [{
					path: '/history/:d/',
					name: 'indexHistory',
					component: indexHistory,
				},
				{
					path: '/history/:d/teammgmt',
					name: 'teammgmt',
					component: teamHistory,
				},
				{
					path: '/history/:d/points',
					name: 'points',
					component: pointsHistory,
				},
				{
					path: '/history/:d/pointsdetail/:t',
					name: 'pointsdetail',
					component: pointsDetailHistory,
				},
				{
					path: '/history/:i/teammembers/:t/:d',
					name: 'teammembers',
					component: teamMembersHistory,
				}
			]
		},

	],
	scrollBehavior() {
		const mainContent = document.querySelector('.main-content');
		if (mainContent) {
			mainContent.scrollTop = 0;
		}
		return {
			x: 0,
			y: 0
		};
	},
});

router.beforeEach((to, from, next) => {
	const isLoggedIn = store.state.isLoggedIn;
	const loginTimestamp = store.state.loginTimestamp;
	const currentTime = new Date().getTime();

	if (isLoggedIn && currentTime - loginTimestamp > 3600000) { // 1小时 = 3600000毫秒
		store.dispatch('setLogout');
		next('/login');
		this.$message({
			type: 'info',
			message: '登录已失效，请重新登陆'
		});
	} else if (!isLoggedIn && to.path !== '/login') {
		next();
	} else {
		next();
	}
});

export default router;