<template>
	<div class="content">
		<div class="sys-block" v-for="(item,index) in softwareList" :key="index">
			<div class="sys-title">
				{{item.title}}
			</div>
			<div class="sys-info">
				{{item.info}}
			</div>
			<div class="software-box">
				<div class="software-item" v-for="(item,index) in item.list" :key="index" :style="{backgroundImage: 'url('+item.img+')'}">
					<div class="software-item-top">
						<img src="@/assets/images/whiteLogo.png" alt="" />
						<span class="software-title">{{item.title}}</span>
					</div>
					<div class="download-btn">
						下载
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	  data() {
			return {
				softwareList: [
					{
						title: "IOS端",
						info: '适用于苹果设备的XXXXXXXXXX',
						list: [
							{
								title: '斗战',
								img: 'https://img0.baidu.com/it/u=3968566014,1252263998&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=889',
								link: ''
							},
							{
								title: 'iPad记录系统',
								img: 'https://img1.baidu.com/it/u=132286371,1244194309&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
								link: ''
							}
						]
					},
					{
						title: "安卓端 - 篮球",
						info: '适用于安卓设备的关于篮球的XXXXXXXXXX',
						list: [
							{
								title: '直播系统',
								img: 'https://img4.runjiapp.com/duoteimg/zhushouimg/img/pic/477/163477/1722578992.jpg',
								link: ''
							},
							{
								title: '安卓控屏',
								img: 'https://apic.tvzhe.com/images/ef/ff/c7252eee8b167bbbd1ab4b4d07b4e7bc2e9bffef.jpg',
								link: ''
							},
							{
								title: '篮球投屏',
								img: 'https://img1.baidu.com/it/u=3946123277,981350101&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=889',
								link: ''
							}
						]
					}
				]
			}
		},
	}
</script>

<style scoped>
	.sys-title {
		height: 60px;
		line-height: 60px;
		font-size: 30px;
		font-weight: bold;
		margin-top: 30px;
	}
	
	.sys-info {
		color: #ababab;
		margin-bottom: 20px;
	}
	
	.software-box {
		display: flex;
		justify-content: space-between;
		padding: 10px;
	}
	
	.software-item {
		height: 200px;
		border-radius: 13px;
		border: 1px solid #1572fe;
		margin: 5px ;
		flex: 1;
		overflow: hidden;
		position: relative;
		background-size: cover
	}
	
	.software-item-top {
		height: 50px;
		background-color: #1572fe;
		color: #fff;
		display: flex;
		align-items: center;
	}
	
	.software-item-top img {
		width: 40px;
		height: 40px;
		margin: 0px 5px;
	}
	
	.download-btn {
		position: absolute;
		bottom: 10px;
		background-color: #1572fe;
		color: #fff;
		border-radius: 30px;
		height: 30px;
		width: 80px;
		text-align: center;
		line-height: 30px;
		margin: auto;
		left: 0;
		right: 0;
	}
</style>